import React, {useState, useEffect} from 'react'
import { useCountdown } from './Components/CountdownDate';
import { Image, Container, Row, Col, Card, ListGroup, Button } from 'react-bootstrap';
import Swal from 'sweetalert2'

const idoLists = [
    // {
    //     tokenName: "PBX",
    //     logo: "img/tokens/PBX.png",
    //     chainLogo: "https://assets.trustwalletapp.com/blockchains/smartchain/assets/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/logo.png",
    //     startDate: "12-15 May 2022",
    //     startDateTimestamp: 1652547600*1000,
    //     contribution: "Below market price Upcoming",
    //     softCap: 0,
    //     hardCap: 500000,
    //     minBuy: 100,
    //     maxBuy: 100000
    // },
    {
        tokenName: "SUN",
        logo: "img/tokens/SUN.png",
        chainLogo: "https://assets.trustwalletapp.com/blockchains/smartchain/assets/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/logo.png",
        startDate: "Coming soon",
        startDateTimestamp: 0*1000,
        contribution: "+30% Bonus Upcoming",
        softCap: 0,
        hardCap: 300000,
        minBuy: 100,
        maxBuy: 1500
    },
    {
        tokenName: "MINE",
        logo: "img/tokens/MINE.png",
        chainLogo: "https://assets.trustwalletapp.com/blockchains/smartchain/assets/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/logo.png",
        startDate: "Coming soon",
        startDateTimestamp: 0*1000,
        contribution: "+30% Bonus Upcoming",
        softCap: 0,
        hardCap: 240000,
        minBuy: 250,
        maxBuy: 240000
    },
]

const IDOlist = () => {

    return (
        <>
            <Container>
                <Row className="mt-5">
                    {
                        idoLists.map((item, index) => (
                            <Col md={4} key={index} className="d-flex justify-content-center">
                                <Card className="p-1 mb-3 salse-list">
                                    <Card.Body>
                                        <p className="d-flex justify-content-end">
                                            <Image src={item.chainLogo} width="24" height="24" />
                                        </p>
                                        <Card.Title className="d-flex align-items-center justify-content-around">
                                            <Image src={item.logo} width="48" height="48" />
                                            <span style={{ fontSize: "24px" }}>
                                                {item.tokenName} <span className="text-warning">Token</span>
                                            </span>
                                        </Card.Title>

                                        <Card.Text className="mt-4">
                                            <button className="btn btn-outline-warning w-100"
                                                style={{ borderRadius: "14px", color: "#333", fontWeight: "400" }}
                                            >
                                                Launch in <span className={item.startDate === "Coming soon" ? "text-danger" : "text-success"} style={{ fontWeight: "500" }}>{item.startDate}</span>
                                            </button>
                                        </Card.Text>

                                        <ListGroup className="mb-3" style={{ fontSize: "12px"}}>
                                            <ListGroup.Item className="d-flex justify-content-between">
                                                <span className="text-muted">Contribution</span>
                                                <span style={{ color: "#fd7e14" }}>{item.contribution}</span>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="d-flex justify-content-between">
                                                <span className="text-muted">Soft Cap</span>
                                                <span>{item.softCap.toLocaleString()} BUSD</span>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="d-flex justify-content-between">
                                                <span className="text-muted">Hard Cap</span>
                                                <span>{item.hardCap.toLocaleString()} BUSD</span>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="d-flex justify-content-center">
                                                <span className="text-muted">Min {item.minBuy.toLocaleString()} BUSD / Max {item.maxBuy.toLocaleString()} BUSD</span>
                                            </ListGroup.Item>
                                        </ListGroup>

                                        <Button variant="warning" style={{ borderRadius: "15px" }} onClick={() => Swal.fire('Coming soon', '', 'info')}>
                                            <Image src="img/metamask.png" height="24" /> Connect Wallet
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))
                    }

                </Row>
            </Container>
        </>
    )
}

export { IDOlist }