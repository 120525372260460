import React from 'react'
import { Navbar, Container, Row, Col, Card, Button } from 'react-bootstrap';
import { IoIosHome, IoIosMedal } from "react-icons/io";

export const SaleList = () => {
  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src="holder.js/100px180" />
              <Card.Body>
                <Card.Title>Card Title</Card.Title>
                <Card.Text>
                  Some quick example text to build on the card title and make up the bulk of
                  the card's content.
                </Card.Text>
                <Button variant="primary">Go somewhere</Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md>md=true</Col>
          <Col md>md=true</Col>
          <Col md>md=true</Col>
        </Row>
      </Container>
    </>
  )
}