import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

import { Headers } from './Components/Header.js';
import { Home } from './Pages/Home.js';
import { SaleList } from './Pages/SaleList.js';

function About() {
  return <h2>About</h2>;
}

function Users() {
  return <h2>Users</h2>;
}

function App() {
  return (
    <div className="App">
      <Headers />
      
      <BrowserRouter>
      <Routes>
        <Route>
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<SaleList />} />
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
    </BrowserRouter>

    </div>
  );
}


export default App;
