import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap';
import { IDOlist } from '../config.js';
import '../App.css'

export const Home = () => {
    return (
        <>
            <Container fluid>
                <Row>
                    <Col md={12} className="d-flex align-items-center justify-content-center bg-header-top" style={{ height:"280px", color: "#fff"}}>
                        <div>
                            <h1 style={{ fontSize: "48px" }}>IDO LaunchPadX</h1>
                            <br/>
                            <p>
                                <span className="text-warning">STARTUP LAUNCH PROGRAM on LaunchPadX</span>
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
            
            <IDOlist />
        </>
    )
}